import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/api-client/fetch-interceptor'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import '@/styles/antDesign.less';
import '@/styles/index.less';
import '@/global.less';
import { msal } from '@/msal'

// @ts-ignore
import { hasPermission } from './utils/common'
import '@/routerPermission'
const app = createApp(App);
// Configure component library
app.use(Antd);
app.use(router);
app.use(store);
app.config.globalProperties.$hasPermission = hasPermission
app.config.globalProperties.$pageName = ''
app.config.globalProperties.$actionNames = []
app.config.globalProperties.$msal = msal;
// Mount when the route is ready
router.isReady().then(() => {
  app.mount('#app');
});


// ts变量检测
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $hasPermission: any,
    $pageName:any,
    $actionNames:any,
    $msal:any
  }
}
