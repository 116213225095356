import Vuex from 'vuex'
import { user } from './modules/user'
import { app } from './modules/app'

export default new Vuex.Store({
  modules: {
    user,
    app
  },
  plugins: []
})