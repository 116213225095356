export const app = {
  state: {
    sidebarCollapsed: false
  },
  actions: {},
  getters: {
    getSidebarCollapsed(state: any) {
      return state.sidebarCollapsed
    }
  },
  mutations: {
    SET_SIDEBAR_COLLAPSED(state: any, sidebarCollapsed: boolean) {
      state.sidebarCollapsed = sidebarCollapsed;
    }
  }
}
