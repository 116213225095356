// @ts-ignore
import * as msalbrower from '@azure/msal-browser'

const msalConfig = {
  auth: {
    authority: process.env.VUE_APP_AZUREAD_AUTHORITY,
    clientId: process.env.VUE_APP_AZUREAD_CLIENTID,
    redirectUri: process.env.VUE_APP_AZUREAD_REDIRECTURI,
  }
};


export const msal = {
  loginRequest : {
    scopes: eval(process.env.VUE_APP_AZUREAD_SCOPES)
  },
   obj: new msalbrower.PublicClientApplication(msalConfig),
   username : "",
   signIn: () => {
     msal.obj.loginRedirect({scopes:eval(process.env.VUE_APP_AZUREAD_SCOPES)});
  },
  signOut: () => {
    const logoutRequest = {
        account: msal.obj.getAccountByUsername(msal.username),
        postLogoutRedirectUri: msalConfig.auth.redirectUri,
    }
    msal.obj.logoutRedirect(logoutRequest)
  },
  getTokenRedirect: async () => {
    let request = msal.loginRequest;
    (request as any).account = msal.obj.getAccountByUsername(msal.username);
    return msal.obj.acquireTokenSilent(request)
        .catch(error => {
            console.warn("silent token acquisition fails. acquiring token using redirect");
            if (error instanceof msalbrower.InteractionRequiredAuthError) {
                return msal.obj.acquireTokenRedirect(request);
            } else {
                console.warn(error);   
            }
        });
    },
    selectAccount:() => {
      const currentAccounts = msal.obj.getAllAccounts();
      if (currentAccounts.length === 0) {
          return;
      } else if (currentAccounts.length > 1) {
          // Add your account choosing logic here
          console.warn("Multiple accounts detected.");
      } else if (currentAccounts.length === 1) {
        msal.username = currentAccounts[0].username;
      }
    }
};
// msal.obj.handleRedirectPromise()
// .then((response) => {
//   debugger
//   if (response !== null) {
//     msal.username = response.account?.username ?? "";
//     } else {
//       msal.selectAccount();
//     }
// })
// .catch((error) => {
//     console.error(error);
// });