import router from '@/router'
import store from '@/store'
import { msal } from '@/msal'
import { RouteRecordRaw, useRouter } from "vue-router";


router.beforeEach(async (to: any, _: any, next: Function) => {
  let response = await msal.obj.handleRedirectPromise();
  if (response !== null) {
    msal.username = response.account?.username ?? "";
    } else {
      msal.selectAccount();
    }
  if (to.name === 'loginSuccess') {
    next()
  } else {
    const currentAccounts = msal.obj.getAllAccounts();
    if (currentAccounts.length > 0) {
      if (!store.getters.getToken || store.getters.getDisplayPages.length == 0) {
        next({ name: 'loginSuccess' })
      } else {
        if (to.name === 'login') {
          next({ path: '/' })
        } else {
          next()
        }
      }
    } else {
      if (to.name === 'login') {
        next()
      } else {
        next({ path: '/login' })
      }
    }
  }
})
router.afterEach(() => {
  window.scrollTo(0, 0);
});
