
import { defineComponent, getCurrentInstance, inject, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { pageActions } from "@/const/pageActionsConfig";

export default defineComponent({
  setup() {
    //获取组件实例
    const internalInstance = getCurrentInstance();
    const { currentRoute } = useRouter();
    // 当前路由发生变化时，调用回调函数
    watch(
      () => currentRoute.value,
      () => {
        // 回调函数
        internalInstance!.appContext.config.globalProperties.$pageName =
          currentRoute.value.meta.permissionPageName;
        if (
          pageActions.filter((pageAction) => {
            return (
              pageAction.PageName === currentRoute.value.meta.permissionPageName
            );
          })[0]
        ) {
          internalInstance!.appContext.config.globalProperties.$actionNames = pageActions.filter(
            (pageAction) => {
              return (
                pageAction.PageName ===
                currentRoute.value.meta.permissionPageName
              );
            }
          )[0].ActionNames;
        }
      }
    );    
      function getPopupContainer(node:any) {
      if (node) {
        return node.parentNode;
      }
      return document.body;
    }
    return {  getPopupContainer };
  },
});
