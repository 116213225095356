import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Main from '@/views/layout/index.vue'

export const constantRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    meta: {
      isMain: true
    },
    children: []
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login/index.vue')
  },
  {
    path: '/login/success',
    name: 'loginSuccess',
    component: () => import('../views/login/success.vue')
  },
  {
    path: '/404',
    component: () => import('@/views/404.vue'),
    meta: { hidden: true }
  }
]

export const asyncRoutes: Array<RouteRecordRaw> = [

  {
    path: '/examiner',
    name: 'examiner management',
    redirect: '/examiner/hub',
    meta: {
      title: 'Examiner Management'
    },
    component: Main,
    children: [
      {
        path: 'hub',
        name: 'hub',
        component: () => import('../views/examiner/examinerHub/index.vue'),
        meta: {
          permissionPageName: 'Examiner Hub',
          title: 'Examiner Hub',
          keepAlive: true
        }
      },
      {
        path: 'planning',
        name: 'planning',
        component: () => import('@/views/examiner/planning/index.vue'),
        meta: {
          permissionPageName: 'Examiner Resource Planning',
          title: 'Examiner Resource Planning'
        }
      },
      {
        path: 'deployment',
        name: 'deployment',
        component: () => import('@/views/examiner/deployment/index.vue'),
        meta: {
          permissionPageName: 'Spk Deployment',
          title: 'SPK Deployment',
          keepAlive: true
        }
      },
      {
        path: 'deployment/vcs-assign/:resourceDeploymentId',
        name: 'vcsAssign',
        component: () => import('@/views/examiner/deployment/assign/online-assign/index.vue'),
        meta: {
          permissionPageName: 'Spk Deployment',
          title: 'VCS Assign',
          hidden: true
        }
      },
      {
        path: 'deployment/ics-assign/:resourceDeploymentId',
        name: 'icsAssign',
        component: () => import('@/views/examiner/deployment/assign/online-assign/index.vue'),
        meta: {
          permissionPageName: 'Spk Deployment',
          title: 'ICS Assign',
          hidden: true
        }
      },
      {
        path: 'deployment/f2f-assign/:resourceDeploymentId',
        name: 'f2fAssign',
        component: () => import('@/views/examiner/deployment/assign/f2f-assign/index.vue'),
        meta: {
          permissionPageName: 'Spk Deployment',
          title: 'In-person Speaking Assign',
          hidden: true
        }
      }
    ]
  },
  {
    path: '/user',
    name: 'user management',
    meta: {
      title: 'User management',
      keepAlive: true,
    },
    redirect: '/user/list',
    component: Main,
    children: [
      {
        path: 'role/list',
        name: 'roleList',
        component: () => import('../views/user/role/index.vue'),
        meta: {
          permissionPageName: 'Role Management',
          title: 'Role List',
          keepAlive: true,
        }
      },
      {
        path: 'list',
        name: 'userList',
        component: () => import('../views/user/index.vue'),
        meta: {
          permissionPageName: 'User Management',
          title: 'User List',
          keepAlive: true,
        }
      },
    ]
  },
  {
    path: '/examinerManagement',
    name: 'examiner info management',
    redirect: '/examinerManagement/examinerProfile',
    meta: {
      title: 'Examiner'
    },
    component: Main,
    children: [
      {
        path: 'examinerProfile',
        name: 'examinerProfile',
        component: () => import('@/views/examinerManagement/examinerProfile/index.vue'),
        meta: {
          permissionPageName: 'Examiner Profile',
          title: 'Examiner Profile',
          keepAlive: true
        }
      },
      {
        path: 'examiner-detail/:examinerId',
        name: 'examinerDetail',
        component: () => import('@/views/examinerManagement/examinerProfile/examinerDetail/index.vue'),
        meta: {
          permissionPageName: 'Examiner Profile',
          title: 'Examiner Profile / Examiner Personal Info',
          hidden: true,
          isHiddenTitle: true
        }
      },
      {
        path: 'examiner-records',
        name: 'examinerRecords',
        component: () => import('@/views/examinerManagement/examinerRecords/index.vue'),
        meta: {
          permissionPageName: 'Certification Records',
          title: 'Certification Records',
          keepAlive: true
        }
      },
      {
        path: 'examiner-records/:id',
        name: 'examinerRecordsDetail',
        component: () => import('@/views/examinerManagement/examinerRecords/detail/index.vue'),
        meta: {
          permissionPageName: 'Certification Records',
          title: 'Certification Records Detail',
          hidden: true,
          isHiddenTitle: true
        }
      },
      {
        path: 'training-Records',
        name: 'Training Records',
        component: () => import('@/views/examinerManagement/trainingRecords/index.vue'),
        meta: {
          permissionPageName: 'Training Records',
          title: 'Training Records',
          keepAlive: true
        }
      },
      {
        path: 'monitor-Records',
        name: 'Monitor Records',
        component: () => import('@/views/examinerManagement/monitorRecords/index.vue'),
        meta: {
          permissionPageName: 'Monitor Records',
          title: 'Monitor Records',
          keepAlive: true
        }
      }
    ]
  },
  {
    path: '/coreDayManagement',
    name: 'Core Day Management',
    redirect: '/coreDayManagement/index',
    meta: {
      title: 'Core Day Management'
    },
    component: Main,
    children: [
      {
        path: 'index',
        name: 'Core Day Management',
        component: () => import('@/views/coreDayManagement/index.vue'),
        meta: {
          permissionPageName: 'Core Day Management',
          title: 'Core Day Management',
          keepAlive: true
        }
      }
    ]
  },
  {
    path: '/availabilityManagement',
    name: 'Availability Management',
    redirect: '/availabilityManagement/index',
    meta: {
      title: 'Availability Management'
    },
    component: Main,
    children: [
      {
        path: 'index',
        name: 'Availability Management',
        component: () => import('@/views/availabilityManagement/availibility/index.vue'),
        meta: {
          permissionPageName: 'Availability Management',  
          title: 'Availability Management',
          keepAlive: true
        }
      },
      {
        path: 'lock-availability',
        name: 'lock-availability',
        component: () => import('@/views/availabilityManagement/lockAvailability/index.vue'),
        meta: {
          permissionPageName: 'Lock Availability',
          title: 'Lock Availability'
        }
      },
      {
        path: 'modifyAvailability/:date',
        name: 'modifyAvailability',
        component: () => import('@/views/availabilityManagement/availibility/modifyAvailability/index.vue'),
        meta: {
          permissionPageName: 'Availability Management',
          title: 'Modify Availability',
          hidden: true,
          isHiddenTitle: true
        }
      },{
        path: 'NonExaminingDate',
        name: 'Non-Examining Date',
        component: () => import('@/views/availabilityManagement/noneExaminingDate/index.vue'),
        meta: {
          permissionPageName: 'Non-Examining Date',  
          title: 'Non-Examining Date',
          keepAlive: true
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes: constantRoutes
})

export default router
