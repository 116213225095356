import { _Client } from '@/api-client'
import store from '@/store'
import { Modal } from 'ant-design-vue'

// check 该region下有没有该action name的操作权限
export const checkAccess = async (accessName: string, regionId: string, showMessage: boolean = true): Promise<boolean> => {
  let hasPermission = await _Client.authClient.checkAccess(accessName, regionId)
  let accessNameArray = accessName.split(':')
  if (!hasPermission && showMessage) {
    Modal.warning({
      content: `You do not have permission to ${accessNameArray[1].toLocaleLowerCase()} for this region`
    })
  }
  return hasPermission
}
// 是否有权限访问某页面的某操作
export const hasPermission = (pageName: string, actionName: string): boolean => {
  const currentPermissions = store.getters.getPermission
  const currentActionPermissions = currentPermissions.find((permission: any) => { return permission.pageName === pageName })!.actionNames
  if (currentActionPermissions && currentActionPermissions.indexOf(`${pageName}:${actionName}`) !== -1) {
    return true
  }
  return false
}

// 对象是undefined或者null
export const IsNullOrUndefined = (object: any): boolean => {
  if (typeof (object) === 'undefined' || object == null) {
    return true
  }
  return false
}

// 导出excel
export const ExportXlsx = (excelStream: any, filenameWithSuffix: string) => {
  if (window.navigator.msSaveOrOpenBlob) {
    // IE
    window.navigator.msSaveOrOpenBlob(excelStream.data, excelStream.fileName)
  } else {
    // Other
    let link = document.createElement('a')
    link.href = window.URL.createObjectURL(excelStream.data)
    link.download = filenameWithSuffix
    link.click()
    URL.revokeObjectURL(link.href)
  }
}

export const GetBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
